<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-21 14:05:39
 * @ Description: Fixer's general job dashboard page in the user's job dashboard section of the app.
 -->

<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
                :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 60px;' : ''"
            >
                <!-- Search Field -->
                <v-text-field
                    dense
                    hide-details
                    outlined
                    color="#2E567A"
                    placeholder="Search..."
                    class="os-13-r"
                    background-color="white"
                    :style="$vuetify.breakpoint.smAndDown ? 'width: calc(100% - 94px); position: absolute; top: 12px; min-width: 0px;' : $vuetify.breakpoint.md ? 'width: 195px; position: absolute; right: 200px; top: -40px; min-width: 0px;' : 'width: 235px; position: absolute; right: 200px; top: -40px; min-width: 0px;'"
                    v-model="mainSearch"
                    clearable
                    @click:clear="clearMainSearch"
                >
                </v-text-field>
                <!-- Search Field -->

                <!-- Search Button -->
                <v-btn
                    class="white--text no-uppercase rounded-r-lg os-13-sb"
                    color="#FFA858"
                    :style="$vuetify.breakpoint.smAndDown ? 'position: absolute; top: 10px; right: 16px;' : 'position: absolute; top: -42px; right: 132px;'"
                    @click="filterJobs"
                >
                    Search
                </v-btn>
                <!-- Search Button -->

                <!-- Add Button -->
                <v-btn
                    style="position: absolute; right: 16px; top: -92px;"
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    v-if="$store.state.user.userContext === 'company'"
                    @click="addJob"
                >
                    <span v-if="!$store.state.general.customBreakpoints.xxs">Add Job</span>
                    <v-icon
                        class="ml-1"
                    >
                        {{ mdiPlusCircleOutline }}
                    </v-icon>
                </v-btn>
                <!-- Add Button -->

                <!-- Card View -->
                <v-row
                    v-if="selectedView === 'card'"
                >
                    <!-- No Jobs -->
                    <v-col
                        v-if="items.length === 0 && !isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        {{ noDataText }}
                    </v-col>
                    <!-- No Jobs -->

                    <!-- Jobs -->
                    <v-col
                        cols="12"
                        md="4"
                        lg="3"
                        v-for="(item, index) in items"
                        :key="index"
                        class="pa-lg-1 pa-xl-3"
                        v-else
                    >
                        <work-job-general-card-component
                            :item="item"
                            @confirmDeleteJob="confirmDeleteJob"
                            @viewJob="viewJob"
                        />
                    </v-col>
                    <!-- Jobs -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading jobs...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- Card View -->

                <!-- List View -->
                <v-row
                    v-if="selectedView === 'list'"
                >
                    <!-- Heading & Search -->
                    <v-col
                        cols="12"
                        class="pb-0 pt-10"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="background-color: white; height: 60px; display: flex; align-items: center;"
                        >
                            All Jobs
                            <v-spacer />
                            <!-- <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="search"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div> -->
                        </div>
                    </v-col>
                    <!-- Heading & Search -->

                    <!-- Jobs -->
                    <v-col
                        cols="12"
                        class="pt-0"
                    >
                        <v-data-table
                            :headers="tableHeaders"
                            :items="items"
                            item-key="id"
                            hide-default-footer
                            hide-default-header
                            disable-pagination
                            :no-data-text="noDataText"
                            :no-results-text="noResultsText"
                            :search="search"
                        >
                            <template
                                v-slot:header="{ props: { headers } }"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            v-for="(item, index) in headers"
                                            :key="index"
                                        >
                                            <div
                                                style="display: flex; align-items: center;"
                                            >
                                                <v-icon
                                                    v-if="item.value.includes('start_date') || item.value.includes('end_date')"
                                                    color="#2E567A"
                                                    style="opacity: 0.65;"
                                                    class="mr-1"
                                                >
                                                    {{ mdiCalendarOutline }}
                                                </v-icon>
                                                {{ item.text }}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <span v-if="item.name">{{ item.name }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No name
                                </span>
                            </template>
                            <template v-slot:[`item.milestone_date`]="{ item }">
                                <span v-if="item.milestone_date">{{ item.milestone_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No milestones
                                </span>
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                <span v-if="item.start_date">{{ item.start_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No start date
                                </span>
                            </template>
                            <template v-slot:[`item.end_date`]="{ item }">
                                <span v-if="item.end_date">{{ item.end_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No end date
                                </span>
                            </template>
                            <template v-slot:[`item.status_name`]="{ item }">
                                <v-icon
                                    x-small
                                    style="margin-right: 1px;"
                                    :color="item.stage_id === 1 ? '#707070' : item.stage_id === 2 ? '#FFA858' : item.stage_id === 3 ? '#08D500' : item.status_id === 7 ? '#32C5FF' : '#E02020'"
                                >
                                    {{ mdiCheckboxBlankCircle }}
                                </v-icon>
                                {{ item.status_name }}
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                {{ item.type_name }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    icon
                                    @click="viewJob(item)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiArrowRightThinCircleOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    icon
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiPrinterOutline }}
                                    </v-icon>
                                </v-btn> -->
                                <v-btn
                                    icon
                                    v-if="item.stage_id === 1 && item.user_id === $store.state.user.userData.id"
                                    @click="confirmDeleteJob(item)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                    <!-- Jobs -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading jobs...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- List View -->
            </v-col>
        </v-row>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Draft'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </div>
</template>
<script>
    import { mdiDeleteOutline, mdiPlusCircleOutline, mdiMagnify, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiCheckboxBlankCircle, mdiCalendarOutline } from '@mdi/js'
    import { WorkJobBoardController, WorkJobDetailController, WorkJobDeleteController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import WorkJobGeneralCardComponent from '@/components/work/job/general/WorkJobGeneralCardComponent.vue'

    export default {
        name: 'WorkJobDashboardGeneralPage',

        components: {
            GeneralConfirmDeleteOverlayComponent,
            WorkJobGeneralCardComponent
        },

        props: {
            selectedNavItem: {
                type: String,
                required: true
            },

            selectedView: {
                type: String,
                required: true
            }
        },

        watch: {
            selectedNavItem: {
                async handler (value) {
                    let shouldRetrieve
                    if (this.$store.state.user.userContext === 'company' && value !== 'Applicants') shouldRetrieve = true
                    else if (this.$store.state.user.userContext === 'individual' && (value !== 'All Jobs' || value !== 'Pending Jobs' || value !== 'Favourites')) shouldRetrieve = true
                    else shouldRetrieve = false

                    if (shouldRetrieve) {
                        this.items = []
                        this.mainSearch = ''
                        this.lastPageLoaded = 0
                        this.totalPages = 0
                        this.isLoading = false
                        this.getJobCategory()
                        await this.getJobs()
                    }
                }
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                if (this.category === 'all') return 'No jobs'
                else return 'No ' + this.category + ' jobs'
            }
        },

        data () {
            return {
                items: [],
                mdiDeleteOutline,
                mdiPlusCircleOutline,
                mdiMagnify,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiCheckboxBlankCircle,
                mdiCalendarOutline,
                tableHeaders: [
                    {
                        text: 'Job Name',
                        value: 'name'
                    },
                    {
                        text: 'Milestone',
                        value: 'milestone_date'
                    },
                    {
                        text: 'Start',
                        value: 'start_date'
                    },
                    {
                        text: 'End',
                        value: 'end_date'
                    },
                    {
                        text: 'Status',
                        value: 'status_name'
                    },
                    {
                        text: 'Type',
                        value: 'type_name'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                workJobBoardController: null,
                workJobDetailController: null,
                workJobDeleteController: null,
                category: null,
                search: '',
                mainSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true,
                jobToDelete: null,
                showDeleteConfirmationOverlayBoolean: false
            }
        },

        beforeMount () {
            this.workJobBoardController = new WorkJobBoardController()
            this.workJobDetailController = new WorkJobDetailController()
            this.workJobDeleteController = new WorkJobDeleteController()
        },

        async mounted () {
            this.getJobCategory()
            await this.getJobs()

            var self = this
        
            const jobContainer = document.getElementById('jobContainer')
            jobContainer.addEventListener('scroll', async () => {
                if (this.selectedView === 'card') {
                    if (jobContainer.offsetHeight + jobContainer.scrollTop + 150 >= jobContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getJobs()
                        }
                    }
                } else {
                    if (jobContainer.offsetHeight + jobContainer.scrollTop + 120 >= jobContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getJobs()
                        }
                    }
                }
            })
        },

        methods: {
            getJobCategory () {
                switch (this.selectedNavItem) {
                case 'All Jobs':
                    this.category = 'all'
                    break;
                case 'Active Jobs':
                    this.category = 'active'
                    break;
                case 'Pending Jobs':
                    this.category = 'pending'
                    break;
                case 'Drafts':
                    this.category = 'draft'
                    break;
                case 'History':
                    this.category = 'history'
                    break;
                }
            },

            async getJobs () {
                try {
                    this.isLoading = true

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    let recordsToRetrieve = 20

                    if (this.selectedView === 'card') recordsToRetrieve = 10
                    else recordsToRetrieve = 20

                    const response = await this.workJobBoardController.retrieve(this.lastPageLoaded, recordsToRetrieve, this.mainSearch, this.category)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.post_date) {
                                const removeTime = item.post_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.post_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.update_date) {
                                const removeTime = item.update_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.update_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.start_date) {
                                const removeTime = item.start_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.start_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.end_date) {
                                const removeTime = item.end_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.end_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.milestone_date) {
                                const removeTime = item.milestone_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.milestone_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            this.items.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const jobContainer = document.getElementById('jobContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && jobContainer.offsetHeight + jobContainer.scrollTop === jobContainer.scrollHeight) await this.getJobs()
                    })
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async clearMainSearch () {
                this.mainSearch = ''
                await this.filterJobs()
            },

            async filterJobs () {
                this.lastPageLoaded = 0
                this.items = []
                await this.getJobs()
            },

            async addJob () {
                try {
                    const draftJob = {
                        name: '',
                        description: null,
                        start_date: null,
                        end_date: null,
                        image_url: null,
                        status: null,
                        industry: {
                            id: null
                        },
                        job_title: {
                            id: null
                        },
                        type: {
                            id: 1
                        },
                        mode: {
                            id: 1
                        }
                    }
                    const response = await this.workJobDetailController.upsert(draftJob)
                    this.$router.push(`/job/${response.job_id}/view`)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            viewJob (item) {
                // Draft/Pending
                if (item.stage_id === 1 || item.stage_id === 2) this.$router.push(`/job/${item.job_id}/view`)
                // Active
                else if (item.stage_id === 3) this.$router.push(`/job/${item.job_id}/overview/${item.applicant_id}`)
                else if (item.stage_id === 4) {
                    // Cancelled/Complete
                    if (item.status_id === 6 || item.status_id === 7) this.$router.push(`/job/${item.job_id}/overview/${item.applicant_id}`)
                    // Cancelled during recruitment
                    else if (item.status_id === 19) this.$router.push(`/job/${item.job_id}/view`)
                }
            },

            confirmDeleteJob (job) {
                this.jobToDelete = job
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteJob (job) {
                try {
                    await this.workJobDeleteController.delete(job)
                    
                    const jobIndex = this.items.findIndex(itemToFind => itemToFind.job_id === job.job_id)
                    if (jobIndex > -1) this.items.splice(jobIndex, 1)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.jobToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteJob(this.jobToDelete)
            }
        }
    }
</script>
<style scoped>
    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        max-width: 250px;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
