<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-11-17 07:52:11
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-20 15:11:45
 * @ Description: Component displaying general job cards on the jobs dashboard.
 -->

<template>
    <v-card
        height="190px"
        width="100%"
        max-width="280px"
        class="rounded-lg pa-3 mx-auto"
        style="position: relative;"
    >
        <v-btn
            icon
            @click="confirmDeleteJob"
            style="position: absolute; right: 4px; bottom: 4px;"
            v-if="item.stage_id === 1"
        >
            <v-icon>
                {{ mdiDeleteOutline }}
            </v-icon>
        </v-btn>
        <v-row
            align="center"
        >
            <v-col
                class="flex-grow-1 flex-shrink-0 os-16-sb pt-1 text-truncate"
            >
                <span v-if="item.name">{{ item.name }}</span>
                <span
                    class="os-12-r"
                    style="color: rgba(0, 0, 0, 0.38);"
                    v-else
                >
                    No name added
                </span>
            </v-col>
            <v-col
                class="flex-shrink-1 flex-grow-0 pt-1 pr-1"
            >
                <v-btn
                    icon
                    @click="viewJob"
                >
                    <v-icon
                        color="#2E567A"
                    >
                        {{ mdiArrowRightThinCircleOutline }}
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                class="os-12-r pt-0 two-lines"
                style="color: #8F9BB3; max-height: 35px; height: 35px;"
                v-html="item.description"
                v-if="item.description"
            />
            <v-col
                class="os-12-r pt-0 two-lines"
                style="color: rgba(0, 0, 0, 0.38); max-height: 35px; height: 35px;"
                v-else
                cols="12"
            >
                No description added
            </v-col>
            <v-col
                cols="12"
                class="pb-0 pt-2"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="4"
                class="py-0 pt-1 px-2"
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Status:
                </div>
                <div
                    :style="item.stage_id === 1 ? 'color: #707070;' : item.stage_id === 2 ? 'color: #FFA858;' : item.stage_id === 3 ? 'color: #08D500;' : item.status_id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                    class="os-13-sb text-truncate"
                >
                    {{ item.status_name }}
                </div>
            </v-col>
            <v-col
                cols="4"
                class="py-0 pt-1 px-2"
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Type:
                </div>
                <div
                    style="color: #2E567A;"
                    class="os-12-sb text-truncate"
                >
                    {{ item.type_name }}
                </div>
            </v-col>
            <v-col
                cols="4"
                class="py-0 pt-1 px-2"
                v-if="item.stage_id !== 1"
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Posted:
                </div>
                <div
                    style="color: #2E567A;"
                    class="os-12-sb text-truncate"
                >
                    <span v-if="item.post_date">{{ item.post_date }}</span>
                    <span v-else>-</span>
                </div>
            </v-col>
            <v-col
                cols="4"
                class="py-0 pt-1 px-2"
                v-else
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Updated:
                </div>
                <div
                    style="color: #2E567A;"
                    class="os-12-sb text-truncate"
                >
                    <span v-if="item.update_date">{{ item.update_date }}</span>
                    <span v-else>-</span>
                </div>
            </v-col>
            <v-col
                cols="4"
                class="py-0 pt-1 px-2"
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Budget:
                </div>
                <div
                    style="color: #2E567A;"
                    class="os-12-sb text-truncate"
                >
                    <span v-if="item.budget_symbol && item.budget">{{ item.budget_symbol }}{{ item.budget }}</span>
                    <span v-else>-</span>
                </div>
            </v-col>
            <v-col
                class="py-0 pt-1 px-2"
            >
                <div
                    style="color: #8F9BB3;"
                    class="os-12-r"
                >
                    Mode:
                </div>
                <div
                    style="color: #2E567A;"
                    class="os-12-sb text-truncate"
                >
                    {{ item.mode_name }}
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    import { mdiArrowRightThinCircleOutline, mdiDeleteOutline } from '@mdi/js'

    export default {
        name: 'WorkJobGeneralCardComponent',

        props: {
            item: {
                type: Object,
                required: true
            }
        },

        data () {
            return {
                mdiArrowRightThinCircleOutline,
                mdiDeleteOutline
            }
        },

        methods: {
            confirmDeleteJob () {
                this.$emit('confirmDeleteJob', this.item)
            },

            viewJob () {
                this.$emit('viewJob', this.item)
            }
        }
    }
</script>
<style scoped>
    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>